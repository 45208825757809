import React, { useState, useEffect } from 'react'
import './cookies.scss'

import 'uikit/dist/css/uikit.css'


const Cookies = ({ props }) => {


    const [cookieText, setCookieText] = useState("");

    useEffect(() => {

        const fetchCookie = async () => {

            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("x-api-key", process.env.GATSBY_FIN_IDD_KEY);

            let raw = JSON.stringify({

                'company_name': "test"
            });

            let requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            try {
                const response = await fetch(
                    process.env.GATSBY_SMH_COOKIE_TEMPLATE,
                    requestOptions
                );
                const data = await response.text();
                setCookieText(data);

            } catch (error) {
                console.log(error);
            }
        }

        fetchCookie();

    }, [])

    return (
        <div className='cookies-container'>
            <div className='cookies-bottom'>
                {
                    cookieText ? <div
                        dangerouslySetInnerHTML={{ __html: cookieText }}
                    /> : ""

                }
               
            </div>
        </div>
    )
}

export default Cookies