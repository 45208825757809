import React from 'react'
import loadable from '@loadable/component'
import Cookies from '../components/cookies/cookies'
import Layout from '../layouts/index'
import { cookiePageContent } from '../utils/informations'
import background from '../assets/about-us-header2.webp'
import { checkTheme } from '../utils/informations'


const SecondaryPagesHeader = loadable(() => import('../components/secondary-pages-header/secondary-pages-header'))


const CookiePolicy = () => {


  

  return (
    <Layout pageTitle={`Cookies - ${checkTheme().pageTitle}`}>
      <SecondaryPagesHeader title={'Privacy & Cookie Policy'} background={background} />
      <Cookies props={cookiePageContent} />

    </Layout>
  )
}

export default CookiePolicy